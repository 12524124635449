import "owl.carousel";
function employeeslide() {
  $(".employee-slider").owlCarousel({
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow"><svg id="Komponente_4_2" data-name="Komponente 4 – 2" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Polygon_3" data-name="Polygon 3" fill="none"><path d="M35.355,0,50,14.645V35.355L35.355,50H14.645L0,35.355V14.645L14.645,0Z" stroke="none"/><path d="M 15.47309112548828 2 L 2 15.47307968139648 L 2 34.52690887451172 L 15.47307968139648 48 L 34.52692031860352 48 L 47.99999237060547 34.52692031860352 L 48 15.47307968139648 L 34.52692031860352 2 L 15.47309112548828 2 M 14.64466857910156 0 L 35.35533905029297 0 L 50 14.64466094970703 L 49.9999885559082 35.35533905029297 L 35.35533905029297 50 L 14.64466094970703 50 L 0 35.35533142089844 L 0 14.64466094970703 L 14.64466857910156 0 Z" stroke="none" fill="#1d140f"/></g><path id="runter" d="M4.977,18.383a.656.656,0,0,0,.656-.656V2.186L8.88,5.433A.656.656,0,0,0,9.808,4.5L5.5.192A.654.654,0,0,0,5.006,0H4.974A.654.654,0,0,0,4.5.192L.192,4.5a.656.656,0,0,0,.928.928l3.2-3.2v15.5a.656.656,0,0,0,.656.656Z" transform="translate(15.417 29.833) rotate(-90)" fill="#1d140f" stroke="#1d140f" stroke-width="1"/></svg></span>',
      '<span class="owl-carousel-arrow"><svg id="Komponente_3_2" data-name="Komponente 3 – 2" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Polygon_4" data-name="Polygon 4" fill="none"><path d="M35.355,0,50,14.645V35.355L35.355,50H14.645L0,35.355V14.645L14.645,0Z" stroke="none"/><path d="M 15.47309112548828 2 L 2 15.47307968139648 L 2 34.52690887451172 L 15.47307968139648 48 L 34.52692031860352 48 L 47.99999237060547 34.52692031860352 L 48 15.47307968139648 L 34.52692031860352 2 L 15.47309112548828 2 M 14.64466857910156 0 L 35.35533905029297 0 L 50 14.64466094970703 L 49.9999885559082 35.35533905029297 L 35.35533905029297 50 L 14.64466094970703 50 L 0 35.35533142089844 L 0 14.64466094970703 L 14.64466857910156 0 Z" stroke="none" fill="#1d140f"/></g><path id="runter" d="M4.977,0a.656.656,0,0,1,.656.656V16.2L8.88,12.95a.656.656,0,0,1,.928.928L5.5,18.191a.654.654,0,0,1-.489.192H4.974a.654.654,0,0,1-.469-.192L.192,13.878a.656.656,0,0,1,.928-.928l3.2,3.2V.656A.656.656,0,0,1,4.977,0Z" transform="translate(15.413 29.833) rotate(-90)" fill="#1d140f" stroke="#1d140f" stroke-width="1"/></svg></span>',
    ],
    loop: true,
    margin: 30,
    autoWidth: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 4,
      },
    },
  });
}

export { employeeslide };
