function header() {

  //Add Class by open Menu
  $('header .navbar-toggler').click(function (event) {
    event.preventDefault();
    $('html').toggleClass('open-nav');
  });
  let mobile=true;

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('#main-menu').toggleClass("open");
    if ($(this).parents('nav').hasClass("show-nav")) {
      // $(this).parent('.children').siblings('.children').removeClass("show");
      // $(this).parent('.children').removeClass("show");
      $(this).removeClass('show');
      $(this).parents('nav').removeClass("show-nav");
      $('html').removeClass('open-nav');
    }
    else
    {
      $(this).addClass("show");
      $(this).parents('nav').addClass("show-nav");
      $('html').addClass('open-nav');
    }
  });

  function stickyHeader(){
    var st = $(window).scrollTop();
    if ( st > 0 ) {
      $('header').addClass('sticky');
    } else {
      $('header').removeClass('sticky');
    }
  }

  stickyHeader();

  $(window).scroll(function(){

    stickyHeader();

  });

  $(document).on('click', function (e) {
      if (!$('#main-menu li.children > a').is(e.target)
        && $('#main-menu li.children > a').has(e.target).length === 0
        && $('#main-menu li.children.show').has(e.target).length === 0
        && $('.navbar-toggler').has(e.target).length === 0
      ) {
        $('#main-menu li.children > a').parent('.children').removeClass('show');
        $('nav').removeClass("show");
        // $('.navbar-toggler').removeClass("open");
      }
  });
  $('#main-menu li.children').on("click", function (e) {
    if ($(this).parents('nav').hasClass("show")) {
      // $(this).parent('.children').siblings('.children').removeClass("show");
      // $(this).parent('.children').removeClass("show");
      $(this).removeClass('show');
      $(this).parents('nav').removeClass("show");
      $('html').removeClass('open-nav');
    }
    else
    {
      $(this).addClass("show");
      $(this).parents('nav').addClass("show");
      if(window.innerWidth < 992)
      {
        $('html').addClass('open-nav');
      }

    }

    //e.stopPropagation();
    //e.preventDefault();
  });
  window.addEventListener("resize", resizeFunction)

  // if (window.innerWidth >= 992) {
  //   mobile=false;
  //   // Dropdown show
  //   $('#main-menu li.children').on("click", function (e) {
  //     if ($(this).parents('nav').hasClass("show")) {
  //       // $(this).parent('.children').siblings('.children').removeClass("show");
  //       // $(this).parent('.children').removeClass("show");
  //       $(this).removeClass('show');
  //       $(this).parents('nav').removeClass("show");
  //       $('html').removeClass('open-nav');
  //     }
  //     else
  //     {
  //       $(this).addClass("show");
  //       $(this).parents('nav').addClass("show");
  //       $('html').addClass('open-nav');
  //     }

  //     //e.stopPropagation();
  //     //e.preventDefault();
  //   });



  // } else{
  //   // Dropdown show
  //   mobile=true;
  //   $('#main-menu li.children, #main-menu li.children > button').on("click", function (e) {
  //     $(this).parent('.children').siblings('.children').removeClass("show");
  //     $(this).toggleClass("show");
  //     //$(this).parent('.children').toggleClass("show");
  //     $(this).parents('nav').toggleClass("show");
  //     e.stopPropagation();
  //     e.preventDefault();
  //   });
  //   // $('#main-menu li.children > a[href="#"], #main-menu li.children > button').on("click", function (e) {

  //   //   $(this).parent('.children').siblings('.children').removeClass("show");
  //   //   $(this).parent('.children').toggleClass("show");
  //   //   $(this).parents('nav').toggleClass("show");
  //   //   e.stopPropagation();
  //   //   e.preventDefault();
  //   // });
  // }
  function resizeFunction()
  {

    if(window.innerWidth >= 992 && mobile)
    {
      mobile=false;
      //$('#main-menu').removeClass('open');
      if($('#main-menu').hasClass('open'))
      {
        $('.navbar-toggler').trigger("click");
      }
      //$('.navbar-toggler').click
      // $('#main-menu li.children, #main-menu li.children > button').off("click");
      $('#main-menu li.children').removeClass('show');
      $('#main-menu li.children').parents('nav').removeClass("show");
      // $('#main-menu li.children').on("click", function (e) {
      //   if ($(this).parents('nav').hasClass("show")) {
      //     // $(this).parent('.children').siblings('.children').removeClass("show");
      //     // $(this).parent('.children').removeClass("show");
      //     $(this).removeClass('show');
      //     $(this).parents('nav').removeClass("show");
      //     $('html').removeClass('open-nav');
      //   }
      //   else
      //   {
      //     $(this).addClass("show");
      //     $(this).parents('nav').addClass("show");
      //     $('html').addClass('open-nav');
      //   }

      //   //e.stopPropagation();
      //   //e.preventDefault();
      // });

    }
    else
    {
      if(window.innerWidth < 992 && !mobile)
      {
        mobile=true;
        $('#main-menu li.children').removeClass('show');
        $('#main-menu li.children').parents('nav').removeClass("show");
        // $('#main-menu li.children').off("click");
        // $('#main-menu li.children, #main-menu li.children > button').on("click", function (e) {
        //   $(this).parent('.children').siblings('.children').removeClass("show");
        //   $(this).toggleClass("show");
        //   //$(this).parent('.children').toggleClass("show");
        //   $(this).parents('nav').toggleClass("show");
        //   e.stopPropagation();
        //   e.preventDefault();
        // });
      }
    }
  }

}
export { header };

function mobileMetaNav() {
  if (window.innerWidth < 1200) {
    console.log('clone');
    $('nav.navbar-meta .navbar-meta-inner').clone().appendTo('.navbar-meta-mobil');
  }
}

export {mobileMetaNav};
