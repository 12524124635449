/* General + Third-Party */
import jQuery from 'jquery';
import { lazyload } from './globals/lazyload';
import { inViewport } from './globals/animation';

/* Layout-Parts */
import { header } from './layout/header';
import { footerHeight } from './layout/footer';

/* Blocks */
import { media_gallery } from '../../blocks/gallery/script';
import { employeeslide } from '../../blocks/employee/script';
import { sliderSimpleOWL } from '../../blocks/slider-simple/script';
import { equalHeight } from '../../blocks/slider-simple/script';

// import { gallery } from '../../blocks/gallery2/script';


jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();
  inViewport();

  //Layout-Parts
  header();
  footerHeight();

  //Blocks
  media_gallery();
  employeeslide();
  sliderSimpleOWL();
  equalHeight();
  // gallery();

});
