import "@fancyapps/fancybox";

function media_gallery() {
$('.text-media__media-wrapper [data-fancybox="gallery"]').fancybox({
  buttons: [
    "slideShow",
    // "zoom",
    // "fullScreen",
    // "share",
    "close"
  ],
  loop: true,
  protect: true
});

}

export { media_gallery };
